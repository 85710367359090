import React from "react"
import { MyCardHelpers } from "@macashipo/mlib"
import HomeCategoryCard from "./Types/HomeCategory"
import HomeBlogCard from "./Types/HomeBlog"
import HomeUsageCard from "./Types/HomeUsage"
import HomePromotionCard from "./Types/HomePromotion"
import HomeLandingCard from "./Types/HomeLanding"
import HomeDiscoverServiceCard from "./Types/HomeDiscoverService"
import HomeHelpsCard from "./Types/HomeHelps"
import HomeAdvantageCard from "./Types/HomeAdvantage"
import HomeCarCard from "./Types/HomeCar"
import NowCard from "./Types/NowCard"

const ProductCard = React.lazy(() => import("./Types/ProductCard"))
const DiscountCard = React.lazy(() => import("./Types/DiscountCard"))
const HistoryCard = React.lazy(() => import("./Types/HistoryCard"))
const ServiceCard = React.lazy(() => import("./Types/ServiceCard"))
// const NowCard = React.lazy(() => import("./Types/NowCard"))
/**Backup chuyến đi của tôi cũ */
const HistoryCardOld = React.lazy(() => import("./Types/HistoryCard_old"))

const OrderCard = React.lazy(() => import("./Types/OrderCard"))
const OrderDetailBlogCard = React.lazy(() => import("./Types/OrderDetailBlog"))
const ReviewCard = React.lazy(() => import("./Types/ReviewCard"))
const List = {
  product_card: ProductCard,
  discount_card: DiscountCard,
  history_card: HistoryCard,
  history_card_old: HistoryCardOld,
  service_card: ServiceCard,
  now_card: NowCard,
  home_card_category: HomeCategoryCard,
  home_card_blog: HomeBlogCard,
  home_card_promotion: HomePromotionCard,
  home_card_usage: HomeUsageCard,
  home_card_landing: HomeLandingCard,
  home_card_discover_service: HomeDiscoverServiceCard,
  home_card_helps: HomeHelpsCard,
  home_card_advantage: HomeAdvantageCard,
  home_card_car: HomeCarCard,
  review_card: ReviewCard,
  order_card: OrderCard,
  order_detail_blog: OrderDetailBlogCard,
}
const MyLibOne = {
  init() {
    MyCardHelpers.addTypesFromListLazy(List)
  },
}

export default MyLibOne
