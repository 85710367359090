import React from "react"
import { MyCardHelpers } from "@macashipo/mlib"
import HomeCategoryCard from "src/modules/app_sigo_responsive/components/MyCard/Types/HomeCategory"
import HomeBlogCard from "src/modules/app_sigo/components/MyCard/Types/HomeBlog"
import HomeUsageCard from "src/modules/app_sigo/components/MyCard/Types/HomeUsage"
import HomePromotionCard from "src/modules/app_sigo/components/MyCard/Types/HomePromotion"
import HomeLandingCard from "src/modules/app_sigo/components/MyCard/Types/HomeLanding"
import HomeDiscoverServiceCard from "src/modules/app_sigo/components/MyCard/Types/HomeDiscoverService"
import HomeHelpsCard from "src/modules/app_sigo/components/MyCard/Types/HomeHelps"
import HomeAdvantageCard from "src/modules/app_sigo/components/MyCard/Types/HomeAdvantage"
import HomeCarCard from "src/modules/app_sigo_responsive/components/MyCard/Types/HomeCar"
import NowCard from "src/modules/app_sigo/components/MyCard/Types/NowCard"

const ProductCard = React.lazy(
  () =>
    import(
      "src/modules/app_sigo_responsive/components/MyCard/Types/ProductCard"
    )
)
const DiscountCard = React.lazy(
  () => import("src/modules/app_sigo/components/MyCard/Types/DiscountCard")
)
const HistoryCard = React.lazy(
  () => import("src/modules/app_sigo/components/MyCard/Types/HistoryCard")
)
const ServiceCard = React.lazy(
  () => import("src/modules/app_sigo/components/MyCard/Types/ServiceCard")
)
// const NowCard = React.lazy(() => import("src/modules/app_sigo/components/MyCard/Types/NowCard"))
/**Backup chuyến đi của tôi cũ */
const HistoryCardOld = React.lazy(
  () => import("src/modules/app_sigo/components/MyCard/Types/HistoryCard_old")
)

const OrderCard = React.lazy(
  () => import("src/modules/app_sigo/components/MyCard/Types/OrderCard")
)
const OrderDetailBlogCard = React.lazy(
  () => import("src/modules/app_sigo/components/MyCard/Types/OrderDetailBlog")
)
const ReviewCard = React.lazy(
  () => import("src/modules/app_sigo/components/MyCard/Types/ReviewCard")
)
const List = {
  product_card: ProductCard,
  discount_card: DiscountCard,
  history_card: HistoryCard,
  history_card_old: HistoryCardOld,
  service_card: ServiceCard,
  now_card: NowCard,
  home_card_category: HomeCategoryCard,
  home_card_blog: HomeBlogCard,
  home_card_promotion: HomePromotionCard,
  home_card_usage: HomeUsageCard,
  home_card_landing: HomeLandingCard,
  home_card_discover_service: HomeDiscoverServiceCard,
  home_card_helps: HomeHelpsCard,
  home_card_advantage: HomeAdvantageCard,
  home_card_car: HomeCarCard,
  review_card: ReviewCard,
  order_card: OrderCard,
  order_detail_blog: OrderDetailBlogCard,
}
const MyLibOne = {
  init() {
    MyCardHelpers.addTypesFromListLazy(List)
  },
}

export default MyLibOne
