import { HLink, HUtils, MyIcon } from "@macashipo/mlib"
import classNames from "classnames"
import { Button } from "reactstrap"
import { homeSlidesFields } from "src/modules/app_sigo/constants/fields"
import styled from "styled-components"

const HomeDiscoverService = {
  fIcon: "Icon",
  fUrl: "url",
}
const HomeDiscoverServiceCard = props => {
  const { data, configMoreInfo } = props
  const img = data?.[homeSlidesFields.fOneSlideImageUrl]
  const title = data?.[homeSlidesFields.fOneSlideTitle]
  const description = data?.[homeSlidesFields.fOneSlideDescription]
  const icon = data?.[HomeDiscoverService.fIcon]
  const moreInfo = data?.[homeSlidesFields.fOneSlideMoreInfo] || {}
  const url = moreInfo?.[HomeDiscoverService.fUrl]
  const btnConfig =
    HUtils.get(configMoreInfo, "websiteSetting.configBtn4EachCard") || {}

  const onHandleBtn = () => {
    if (url) {
      HLink.openUrl(url, "_blank")
    }
  }

  return (
    <Wrap>
      {icon && <MyIcon data={`type:${icon}`} />}
      {title && <div className="title">{title}</div>}
      {description && (
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
      )}
      {url && btnConfig?.canShow && (
        <Button
          color={btnConfig?.color}
          className={classNames(btnConfig?.className)}
          onClick={onHandleBtn}
          disabled={!url ? true : false}
        >
          {btnConfig?.title}
        </Button>
      )}
    </Wrap>
  )
}
const Wrap: any = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  & svg {
    height: 113px;
  }
  & .title {
    color: var(--color-text-000);
    font-size: 20px;
    font-weight: 700;
    padding-top: 15px;
  }
  & .description {
    color: #656565;
    font-size: 16px;
    padding-top: 15px;
    max-width: 217px;
    text-align: center;
  }
  & button {
    margin-top: 20px;
    padding: 7px 20px;
    font-size: 14px !important;
  }
`
export default HomeDiscoverServiceCard
