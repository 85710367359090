import React from "react"
import MyUIHelpers from "src/core/components/MyUI/MyUIHelpers"
/** Login */
import MUILogin from "src/modules/app_sigo/components/MyUI/Types/Login/Login"
/** Không import lazy */
import MyUIHomeRentalCar from "src/modules/app_sigo/components/MyUI/Types/Home/HomeRentalCar"
import MUIHelmet from "src/modules/app_sigo/components/MyUI/Types/Helmet"
import MUIButtonRefresh from "src/modules/app_sigo/components/MyUI/Types/Category/ButtonRefresh"
import MUIHeaderCategoryFullv2 from "src/modules/app_sigo/components/MyUI/Types/HeaderCategoryFullv2"
import MUIHeaderCategory from "src/modules/app_sigo/components/MyUI/Types/HeaderCategory"
import MUIButtonForm from "src/modules/app_sigo/components/MyUI/Types/ButtonForm"
import MyUIWLoadingSkeleton from "src/modules/app_sigo/components/MyUI/Types/WLoadingSkeleton"
import MUISlideCard from "src/modules/app_sigo/components/MyUI/Types/SlideCard"
import MUIAvatar from "src/modules/app_sigo/components/MyUI/Types/Avatar"
import MUIBanner from "src/modules/app_sigo/components/MyUI/Types/Banner"
import MUIHeader from "src/modules/app_sigo/components/MyUI/Types/Header"
import MUIHomeSearchbar from "src/modules/app_sigo/components/MyUI/Types/Home/Searchbar"
import MyUIHeaderMobileV2 from "src/modules/app_sigo/components/MyUI/Types/HeaderMobilev2"
import MUIFancybox from "src/modules/app_sigo/components/MyUI/Types/Fancybox"
import MyUIContentOverlay from "src/modules/app_sigo/components/MyUI/Types/WContentOverlay"
import MyUITripDetail from "src/modules/app_sigo/components/MyUI/Types/TripDetail"
/** Detail */
import MyUICarName from "src/modules/app_sigo/components/MyUI/Types/Detail/CarName"
import MyUICarImageSlide from "src/modules/app_sigo/components/MyUI/Types/Detail/CarImageSlide"
/** Trip details */
import GeneralInfo from "src/modules/app_sigo/components/MyUI/Types/TripDetail/GeneralInfo"
import VehicleInfo from "src/modules/app_sigo/components/MyUI/Types/TripDetail/VehicleInfo"
import PaymentInfo from "src/modules/app_sigo/components/MyUI/Types/TripDetail/PaymentInfo"
import OwnerInfo from "src/modules/app_sigo/components/MyUI/Types/TripDetail/OwnerInfo"
import SupportInfo from "src/modules/app_sigo/components/MyUI/Types/TripDetail/SupportInfo"
import BlogSuggestion from "src/modules/app_sigo/components/MyUI/Types/TripDetail/BlogSuggestion"
import InsuranceInfo from "src/modules/app_sigo/components/MyUI/Types/TripDetail/InsuranceInfo"
import Action from "src/modules/app_sigo/components/MyUI/Types/TripDetail/Action"

/**  */
const MyUIWLoadingOverlay = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/WLoadingOverlay")
)
const MyUISGButton = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/SGButton")
)

// const MUIHeader = React.lazy(
//   () => import("src/modules/app_sigo/components/MyUI/Types/Header")
// )
// const MUIBanner = React.lazy(
//   () => import("src/modules/app_sigo/components/MyUI/Types/Banner")
// )
const MUIComingSoon = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/CommingSoon")
)
const MUINotAvailable = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/NotAvailable")
)
// const MUIAvatar = React.lazy(
//   () => import("src/modules/app_sigo/components/MyUI/Types/Avatar")
// )
const MUIInsurrance = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/Insurance")
)
const MUIWContent = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/WContent")
)
const MUIAutoLoadList = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/AutoLoadList")
)
// const MUIButtonForm = React.lazy(
//   () => import("src/modules/app_sigo/components/MyUI/Types/ButtonForm")
// )
const MUIAlertTemplate = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/AlertTemplate")
)
const MUIImageSlides = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/ImageSlides")
)
// const MUIFancybox = React.lazy(
//   () => import("src/modules/app_sigo/components/MyUI/Types/Fancybox")
// )
const MUISearchBoxWData = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/SearchBoxWData")
)
const MUIListCard = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/ListCard")
)
// const MUISlideCard = React.lazy(
//   () => import("src/modules/app_sigo/components/MyUI/Types/SlideCard")
// )
const MyUIPopover = React.lazy(() => import("./Types/TippyPopover"))
const MyUIDownloadAppInfo = React.lazy(() => import("./Types/DownloadAppInfo"))
/** Home */
// const MUIHomeSearchbar = React.lazy(
//   () => import("src/modules/app_sigo/components/MyUI/Types/Home/Searchbar")
// )
const MyUIHomeCarSuggest = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/Home/HomeCarSuggest")
)
/** Category */
// const MUIHeaderCategory = React.lazy(
//   () => import("src/modules/app_sigo/components/MyUI/Types/HeaderCategory")
// )
const MUIFilterCategory = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/FilterCategory")
)
// const MUIHeaderCategoryFullv2 = React.lazy(
//   () =>
//     import("src/modules/app_sigo/components/MyUI/Types/HeaderCategoryFullv2")
// )
const MUIFilterSpecialAttribute = React.lazy(
  () =>
    import(
      "src/modules/app_sigo/components/MyUI/Types/Category/FilterSpecialAttribute"
    )
)
// const MUIButtonRefresh = React.lazy(
//   () =>
//     import("src/modules/app_sigo/components/MyUI/Types/Category/ButtonRefresh")
// )
/** Detail */
const MUIProduct = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/Detail/Product")
)
const MUIBookingInfo = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/Detail/BookingInfo")
)
const MUIConfirmBooking = React.lazy(
  () =>
    import("src/modules/app_sigo/components/MyUI/Types/Detail/ConfirmBooking")
)
const MUIHeaderDetail = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/Detail/HeaderDetail")
)
const MUIVehicleMap = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/Detail/VehicleMap")
)
const MUIVehicleDeliveryAdress = React.lazy(
  () =>
    import(
      "src/modules/app_sigo/components/MyUI/Types/Detail/VehicleDeliveryAdress"
    )
)
const MyUIBookingConfirm = React.lazy(
  () =>
    import("src/modules/app_sigo/components/MyUI/Types/Detail/BookingConfirm")
)
const MyUIMessageForOwner = React.lazy(
  () =>
    import("src/modules/app_sigo/components/MyUI/Types/Detail/MessageForOwner")
)
const MyUIPriceDetails = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/Detail/PriceDetails")
)
const MyUISelectSecurity = React.lazy(
  () =>
    import("src/modules/app_sigo/components/MyUI/Types/Detail/SelectSecurity")
)
const MyUIBookingSuccess = React.lazy(
  () =>
    import("src/modules/app_sigo/components/MyUI/Types/Detail/BookingSuccess")
)
/** Order */
const MUIOrderAction = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/Order/Action")
)
// const MUIOrderDetail = React.lazy(
//   () => import("src/modules/app_sigo/components/MyUI/Types/Order/Detail")
// )
const MUIOrderProgress = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/Order/Progress")
)
const MUIOrderInsurrance = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/Order/Insurrance")
)
const MUIOrderImages = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/Order/Images")
)
const MUIOrderPrices = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/Order/Prices")
)
const MUIOrderOthers = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/Order/Others")
)
const MUIOrderTrader = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/Order/Trader")
)
const MUIOrderHeader = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/Order/Header")
)
/*OrderDetail */
const MUIOrderDetail = React.lazy(
  () =>
    import("src/modules/app_sigo/components/MyUI/Types/OrderDetail/OrderDetail")
)
const MUIOrderDetailGeneralInfo = React.lazy(
  () =>
    import(
      "src/modules/app_sigo/components/MyUI/Types/OrderDetail/OrderDetailGeneralInfo"
    )
)
const MUIOrderDetailRenterInfo = React.lazy(
  () =>
    import(
      "src/modules/app_sigo/components/MyUI/Types/OrderDetail/OrderDetailRenterInfo"
    )
)
const MUIOrderdetailBookingInfo = React.lazy(
  () =>
    import(
      "src/modules/app_sigo/components/MyUI/Types/OrderDetail/OrderDetailBookingInfo"
    )
)
const MUIOrderDetailPaymentInfo = React.lazy(
  () =>
    import(
      "src/modules/app_sigo/components/MyUI/Types/OrderDetail/OrderDetailPaymentInfo"
    )
)
const MUIOrderDetailInsuranceInfo = React.lazy(
  () =>
    import(
      "src/modules/app_sigo/components/MyUI/Types/OrderDetail/OrderDetailInsuranceInfo"
    )
)
const MUIOrderDetailAction = React.lazy(
  () =>
    import(
      "src/modules/app_sigo/components/MyUI/Types/OrderDetail/OrderDetailAction"
    )
)
const MUIOrderDetailProfitInfo = React.lazy(
  () =>
    import(
      "src/modules/app_sigo/components/MyUI/Types/OrderDetail/MUIOrderDetailProfitInfo"
    )
)
const MyUIHeaderOrders = React.lazy(
  () =>
    import(
      "src/modules/app_sigo/components/MyUI/Types/OrderDetail/HeaderOrders"
    )
)
/** use admin v5 */
const MUIAdmintableTablListCard = React.lazy(
  () =>
    import("src/modules/app_sigo/components/MyUI/Types/AdmintableTablListCard")
)
/** Popup trip detail */
const MyUICountdownTime = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/CountdownTime")
)
/** User profile */
const MyUIUserAvatar = React.lazy(
  () =>
    import("src/modules/app_sigo/components/MyUI/Types/UserProfle/UserAvatar")
)
const MyUIUserMainInfo = React.lazy(
  () =>
    import("src/modules/app_sigo/components/MyUI/Types/UserProfle/UserMainInfo")
)
const MyUIReportUser = React.lazy(
  () =>
    import("src/modules/app_sigo/components/MyUI/Types/UserProfle/UserReport")
)
const MyUIUserReview = React.lazy(
  () =>
    import("src/modules/app_sigo/components/MyUI/Types/UserProfle/UserReview")
)
/** Verify account */
const MyUIUploadType = React.lazy(
  () =>
    import("src/modules/app_sigo/components/MyUI/Types/Verify/VerifyUploadType")
)
const MyUIVerifyFace = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/Verify/VerifyFace")
)
const MyUIEnterOtp = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/Login/EnterOtp")
)
const MyUIUserRegister = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/Login/UserRegister")
)
const MyUIForgotPassword = React.lazy(
  () =>
    import("src/modules/app_sigo/components/MyUI/Types/Login/ForgotPassword")
)
const MyUIEnterNewPassword = React.lazy(
  () =>
    import("src/modules/app_sigo/components/MyUI/Types/Login/EnterNewPassword")
)
const MyUIFooter = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/Footer")
)
/** Help us */
const MyUIHelpusDirection = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/Helpus/Direction")
)
const MyUIFAQs = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/Helpus/FAQs")
)
/** Html page */
const MyUITopic = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/Topic")
)
const MyUIBlog = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/Blog")
)
const MyUIOneNotification = React.lazy(
  () =>
    import(
      "src/modules/app_sigo/components/MyUI/Types/AdminTableCard/OneNotification"
    )
)
/** Payment */
const MyUIPaymentWallet = React.lazy(
  () =>
    import("src/modules/app_sigo/components/MyUI/Types/Payment/PaymentWallet")
)
const MyUIBankAccount = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/Payment/BankAccount")
)
const MyUIWithdrawPayment = React.lazy(
  () =>
    import("src/modules/app_sigo/components/MyUI/Types/Payment/WithdrawPayment")
)
const MyUIAddBankAccount = React.lazy(
  () =>
    import("src/modules/app_sigo/components/MyUI/Types/Payment/AddBankAccount")
)
const MyUITransactionHistories = React.lazy(
  () =>
    import(
      "src/modules/app_sigo/components/MyUI/Types/Payment/TransactionHistories"
    )
)
const MyUIVerifyLoadingAnimation = React.lazy(
  () =>
    import("src/modules/app_sigo/components/MyUI/Types/VerifyLoadingAnimation")
)
/** Admin table card type */
const MyUISavingCoin = React.lazy(
  () =>
    import(
      "src/modules/app_sigo/components/MyUI/Types/AdminTableCard/SavingCoin"
    )
)
const MyUISelectAddress = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/SelectAddress")
)
const MyUIShare = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/Share")
)
const MyUIOrderDetailOld = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/Order/Detail_old")
)
const MyUICancelOrder = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/Order/CancelOrder")
)
const MyUIReviewOrder = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/Order/ReviewOrder")
)
const WrapModalCustomHeader = React.lazy(
  () => import("src/modules/app_sigo/components/MyUI/Types/WModalCustomHeader")
)

const List = {
  sg_header: MUIHeader,
  sg_footer: MyUIFooter,
  sg_banner: MUIBanner,
  sg_avatar: MUIAvatar,
  sg_insurance: MUIInsurrance,
  sg_comming_soon: MUIComingSoon,
  sg_not_available: MUINotAvailable,
  wcontent: MUIWContent,
  autoload_list: MUIAutoLoadList,
  button_form: MUIButtonForm,
  alert: MUIAlertTemplate,
  image_slides: MUIImageSlides,
  fancybox: MUIFancybox,
  search_box_withdata: MUISearchBoxWData,
  list_card: MUIListCard,
  slide_card: MUISlideCard,
  popover: MyUIPopover,
  helmet: MUIHelmet,
  sg_download_app_info: MyUIDownloadAppInfo,
  verify_loading_animation: MyUIVerifyLoadingAnimation,
  /** Home */
  sg_home_searchbar: MUIHomeSearchbar,
  sg_home_rental_car: MyUIHomeRentalCar,
  sg_home_car_suggest: MyUIHomeCarSuggest,
  /**/
  /** Category */
  sg_header_category: MUIHeaderCategory,
  sg_filter_category: MUIFilterCategory,
  sg_header_category_full_v2: MUIHeaderCategoryFullv2,
  sg_filter_special_attribute: MUIFilterSpecialAttribute,
  sg_button_refresh: MUIButtonRefresh,
  /**/
  /** Detail */
  sg_product: MUIProduct,
  sg_booking_info: MUIBookingInfo,
  sg_confirm_booking: MUIConfirmBooking,
  sg_header_detail: MUIHeaderDetail,
  sg_vehicle_map: MUIVehicleMap,
  sg_vehicle_delivery_adress: MUIVehicleDeliveryAdress,
  sg_car_name: MyUICarName,
  sg_car_img_slide: MyUICarImageSlide,
  sg_booking_confirm: MyUIBookingConfirm,
  sg_message_for_owner: MyUIMessageForOwner,
  sg_price_details: MyUIPriceDetails,
  sg_select_secutity: MyUISelectSecurity,
  sg_booking_success: MyUIBookingSuccess,
  /** Login */
  sg_login: MUILogin,
  sg_enter_otp: MyUIEnterOtp,
  sg_user_register: MyUIUserRegister,
  sg_forgot_pwd: MyUIForgotPassword,
  sg_enter_new_pwd: MyUIEnterNewPassword,
  /** History */
  sg_order_action: MUIOrderAction,
  sg_order_detail: MUIOrderDetail,
  sg_order_progress: MUIOrderProgress,
  sg_order_insurrance: MUIOrderInsurrance,
  sg_order_images: MUIOrderImages,
  sg_order_prices: MUIOrderPrices,
  sg_order_others: MUIOrderOthers,
  sg_order_trader: MUIOrderTrader,
  sg_order_header: MUIOrderHeader,
  /** use admin v5 */
  admintable_table_list_card: MUIAdmintableTablListCard,
  /** popup trip detail */
  countdown_time: MyUICountdownTime,
  /** User Profile */
  sg_user_avatar: MyUIUserAvatar,
  sg_user_main_info: MyUIUserMainInfo,
  sg_user_report_user: MyUIReportUser,
  sg_user_review: MyUIUserReview,
  /** Verify Account */
  sg_verify_face: MyUIVerifyFace,
  sg_verify_upload_types: MyUIUploadType,
  /** Help us */
  sg_helpus_direction: MyUIHelpusDirection,
  sg_faqs: MyUIFAQs,
  /** Html ui */
  sg_blog_content: MyUIBlog,
  sg_topic_content: MyUITopic,
  /** notification */
  sg_one_notification: MyUIOneNotification,
  /** payment */
  sg_payment_wallet: MyUIPaymentWallet,
  sg_bank_account: MyUIBankAccount,
  sg_withdraw_payment: MyUIWithdrawPayment,
  sg_add_bank_account: MyUIAddBankAccount,
  sg_transactions_histories: MyUITransactionHistories,
  /** OrderDetail */
  sg_orderdetail_general_info: MUIOrderDetailGeneralInfo,
  sg_orderdetail_renter_info: MUIOrderDetailRenterInfo,
  sg_orderdetail_booking_info: MUIOrderdetailBookingInfo,
  sg_orderdetail_payment_info: MUIOrderDetailPaymentInfo,
  sg_orderdetail_insurance_info: MUIOrderDetailInsuranceInfo,
  sg_orderdetail_action: MUIOrderDetailAction,
  sg_orderdetail_profit_info: MUIOrderDetailProfitInfo,
  sg_header_orders: MyUIHeaderOrders,
  /** */
  sg_saving_card: MyUISavingCoin,
  sg_loading_overlay: MyUIWLoadingOverlay,
  sg_button: MyUISGButton,
  sg_select_address: MyUISelectAddress,
  sg_loading_skeleton: MyUIWLoadingSkeleton,
  sg_header_mobile_v2: MyUIHeaderMobileV2,
  sg_share: MyUIShare,
  sg_content_overlay: MyUIContentOverlay,
  // backup Chuyến đi của tôi cũ
  sg_order_detail_old: MyUIOrderDetailOld,
  /** Trip details */
  sg_trip_detail: MyUITripDetail,
  tripdetail_general_info: GeneralInfo,
  tripdetail_vehicle_info: VehicleInfo,
  tripdetail_payment_info: PaymentInfo,
  tripdetail_owner_info: OwnerInfo,
  tripdetail_support_info: SupportInfo,
  tripdetail_blog_suggestion: BlogSuggestion,
  tripdetail_insurance_info: InsuranceInfo,
  tripdetail_action: Action,
  sg_cancel_order: MyUICancelOrder,
  sg_review_order: MyUIReviewOrder,
  /** */
  sg_wrap_modal_custom_header: WrapModalCustomHeader,
}
const MyUIOne = {
  init() {
    MyUIHelpers.addTypesFromListLazy(List)
  },
}
export default MyUIOne
