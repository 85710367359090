import { HLink, HUtils } from "@macashipo/mlib"
import classNames from "classnames"
import { Button } from "reactstrap"
import { homeSlidesFields } from "src/modules/app_sigo/constants/fields"
import styled from "styled-components"

const HomeLanding = {
  fCarCount: "carCount",
  fDate: "date",
}
const HomeLandingCard = props => {
  const { data, config } = props

  const _url = HUtils.get(data, `${homeSlidesFields.fOneSlideMoreInfo}.url`)
  const img = data?.[homeSlidesFields.fOneSlideImageUrl]
  const title = data?.[homeSlidesFields.fOneSlideTitle]
  const description = data?.[homeSlidesFields.fOneSlideDescription]
  const moreInfo = data?.[homeSlidesFields.fOneSlideMoreInfo] || {}
  const configBtn = HUtils.get(moreInfo, "websiteSetting.configBtn") || {}
  const configImage = HUtils.get(config, "itemConfig.configImage") || {}
  const onHandleBtnLandingCard = () => {
    if (configBtn && configBtn?.url) {
      HLink.openUrl(configBtn?.url, "_blank")
    }
  }

  return (
    <Wrap>
      <WrapContent bgImage={img} configImage={configImage}>
        <div className="wrap-content">
          {title && (
            <div
              className="title"
              dangerouslySetInnerHTML={{ __html: title }}
            ></div>
          )}
          {description && (
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
          )}
          {configBtn?.showBtn && (
            <Button
              color={configBtn?.color}
              className={classNames(configBtn?.className)}
              onClick={onHandleBtnLandingCard}
            >
              {configBtn?.title}
            </Button>
          )}
        </div>
      </WrapContent>
    </Wrap>
  )
}
const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const WrapContent: any = styled.div`
  width: ${(props: any) =>
    props?.configImage?.defaultWidth
      ? props?.configImage?.defaultWidth
      : "100%"};
  max-width: 100%;
  height: ${(props: any) =>
    props?.configImage?.defaultHeight
      ? props?.configImage?.defaultHeight
      : "486px"};
  background-image: linear-gradient(
      90.05deg,
      rgba(0, 0, 0, 0.6) 6.91%,
      rgba(0, 0, 0, 0) 58.34%
    ),
    ${(props: any) => `url("${props.bgImage}")`};
  background-repeat: no-repeat;
  background-position: ${(props: any) =>
    props?.configImage?.backgroundPosition
      ? props?.configImage?.backgroundPosition
      : "center"};
  object-fit: cover;
  background-size: cover;
  border-radius: 15px;
  padding: 15px;
  position: relative;

  & .wrap-content {
    position: absolute;
    top: 50%;
    left: 40px;
    transform: translateY(-50%);
  }

  & .title {
    font-size: 36px;
    color: var(--color-text-fff);
    font-weight: 700;
    line-height: 49px;
  }
  & .description {
    color: var(--color-text-fff);
    font-size: 20px;
    margin-top: 15px;
    line-height: 29px;
  }
  & button {
    margin-top: 25px;
    text-transform: uppercase;
    padding: 9px 30px;
    font-weight: 700;
  }
  @media (max-width: 1499px) {
    width: ${(props: any) =>
      props?.configImage?.desktopWidth
        ? props?.configImage?.desktopWidth
        : "100%"};
    height: ${(props: any) =>
      props?.configImage?.desktopHeight
        ? props?.configImage?.desktopHeight
        : "486px"};
    background-position: ${(props: any) =>
      props?.configImage?.desktopBackgroundPosition
        ? props?.configImage?.desktopBackgroundPosition
        : "center"};
  }
  @media (min-width: 992px) {
    max-width: calc(100vw - 100px);
  }
  @media (max-width: 1199px) {
    width: ${(props: any) =>
      props?.configImage?.tabletWidth
        ? props?.configImage?.tabletWidth
        : "100%"};
    height: ${(props: any) =>
      props?.configImage?.tabletHeight
        ? props?.configImage?.tabletHeight
        : "486px"};
    background-position: ${(props: any) =>
      props?.configImage?.tabletBackgroundPosition
        ? props?.configImage?.tabletBackgroundPosition
        : "center"};
  }
  @media (max-width: 767px) {
    width: ${(props: any) =>
      props?.configImage?.mobileWidth
        ? props?.configImage?.mobileWidth
        : "100%"};
    height: ${(props: any) =>
      props?.configImage?.mobileHeight
        ? props?.configImage?.mobileHeight
        : "486px"};
    background-position: ${(props: any) =>
      props?.configImage?.mobileBackgroundPosition
        ? props?.configImage?.mobileBackgroundPosition
        : "center"};
    & .wrap-content {
      left: 20px;
    }
    & .title {
      font-size: 30px;
      line-height: 39px;
    }
    & .description {
      font-size: 18px;
      line-height: 27px;
    }
  }
`
export default HomeLandingCard
